var socket = io.connect( 'https://sockets2.asistacasos.com/' );
//var socket = io.connect( 'https://sockets.test.asistacasos.com' );

//var socket = io.connect( 'https://nodeServer/nodeServer.js' );


socket.on( 'case_allow', function( data ) {
    let post = data;
    $.ajax({
        url: `https://app.asistacasos.com/AppLaravel/public/api/SpambAsgAshaQehhDasjNASa1h!`,
        //url: `http://127.0.0.1:8000/api/SpambAsgAshaQehhDasjNASa1h!`,
        method: 'POST',
        data: post,
        async: true,
        success: (res)=>{
        }
    });
    let id = '#idCase'+data.idCase;
    let bottonFcii = '#idCaseFcii'+data.idCase;
    let bottonDigipat = '#idCaseDigipat'+data.idCase;
    let bottonPwa = '#idCasePwa'+data.idCase;
    let bottonAsista = '#idCaseAsista'+data.idCase;
    let bottonMovil = '#idCaseMovil'+data.idCase;
    if(data.state === 0){
        $(id).attr('disabled', 'disabled');
        $(bottonAsista).attr('disabled', 'disabled');
        $(bottonFcii).attr('disabled', 'disabled');
        $(bottonDigipat).attr('disabled', 'disabled');
        $(bottonPwa).attr('disabled', 'disabled');
        $(bottonMovil).attr('disabled', 'disabled');
    }else{
        $(id).prop("disabled", false);
        $(bottonAsista).prop("disabled", false);
        $(bottonFcii).prop("disabled", false);
        $(bottonDigipat).prop("disabled", false);
        $(bottonPwa).prop("disabled", false);
        $(bottonMovil).prop("disabled", false);
    }

    let smallCase = '#smallNameModify'+data.idCase;
    $(smallCase).text(data.user.name);
  });
